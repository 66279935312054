import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "sunyata",
  "date": "2019-07-15T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`sunyata, in Buddhist philosophy, the voidness that constitutes ultimate reality; sunyata is seen not as a negation of existence but rather as the undifferentiation out of which all apparent entities, distinctions, and dualities arise. Although the concept is encountered occasionally in early Pāli texts, its full implications were developed by the 2nd-century Indian philosopher Nāgārjuna. The school of philosophy founded by him, the Mādhyamika (Middle Way), is sometimes called the Śūnyavāda, or Doctrine That All Is Void.`}</p>
    <p>{`The term sunyata may also be used as a recognition of anattā, or the absence of any self apart from the five skandhas (mental and physical elements of existence).`}</p>
    <p>{`emptiness, also called Nothingness, or Void, in mysticism and religion, a state of “pure consciousness” in which the mind has been emptied of all particular objects and images; also, the undifferentiated reality (a world without distinctions and multiplicity) or quality of reality that the emptied mind reflects or manifests. The concept, with a subjective or objective reference (sometimes the two are identified), has figured prominently in mystical thought in many historical periods and parts of the world. The emptying of the mind and the attainment of an undifferentiated unity is a theme that runs through mystical literature from the Upaniṣads (ancient Indian meditative treatises) to medieval and modern Western mystical works. The concepts of hsü (q.v.) in Taoism, sunyata (q.v.) in Mahāyāna Buddhism, and the En Sof in Jewish mysticism are pertinent examples of “emptiness,” or “holy Nothing,” doctrines. Buddhism, with its basic religious ultimate of Nirvāṇa (q.v.), as well as its development of the sunyata doctrine, has probably articulated emptiness more fully than any other religious tradition; it has also affected some modern Western considerations of the concept. A good deal of 19th–20th century Western imaginative literature has been concerned with emptiness, as has a certain type of Existentialist philosophy and some forms of the Death of God movement. The particular meanings of “emptiness” vary with the particular context and the religious or cultural tradition in which it is used.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      